import React, { useEffect, useState, useRef, useMemo } from "react";
import PropTypes from "prop-types";
import { Checkbox } from "antd";
import { TitleContainer, AddList } from "common-components";
import EditGroup from "../EditGroup";
import { UiCheckSetting, UiTitleHeader, ListGroup } from "./CheckSetting.style";

/**
 * 檢核設定內容
 */

export const CheckSetting = ({ checkMetaData, outputMetaData, onSaveHandle, currentTab, infos }) => {
	const [checkIsEdit, setCheckIsEdit] = useState(false);
	const [outputIsEdit, setOutputIsEdit] = useState(false);

	const initialCheckData = useRef(null);
	const [checkListData, setCheckListData] = useState([]);
	const initialOutputData = useRef(null);
	const [outputListData, setOutputListData] = useState([]);

	// 取得學制科目
	const eduSubject = useMemo(() => {
		if (!infos) return "";
		const { eduSubject } = infos;
		return eduSubject;
	}, [infos]);

	// 依照學制科目 預設的必填欄位 無法取消勾選
	const disabledArray = useMemo(() => {
		switch (eduSubject) {
			// 國中國文、國中英語
			case "JPC":
			case "JEN":
				return ["SOURCE", "COGNITIVE", "DIFFICULTY", "KNOWLEDGE", "QUES_TYPE"];

			// 國中數學、國中歷史、國中地理、國中理化、國中生物、國中地科、國中公民
			case "JMA":
			case "JHI":
			case "JGE":
			case "JPY":
			case "JBI":
			case "JEA":
			case "JCT":
				return ["SOURCE", "COGNITIVE", "DIFFICULTY", "KNOWLEDGE", "QUES_TYPE", "TOPIC"];

			// 國中科技、國中生活科技、國中資訊科技、國中健體、國中綜合活動
			case "JTC":
			case "JIT":
			case "JLT":
			case "JPE":
			case "JCO":
				return ["SOURCE", "COGNITIVE", "DIFFICULTY", "KNOWLEDGE", "QUES_TYPE", "TOPIC"];

			// 國小國語
			case "ECH":
				return ["SOURCE", "COGNITIVE", "DIFFICULTY", "KNOWLEDGE", "QUES_TYPE"];

			// 國小數學、國小社會、國小自然、國小生活
			case "EMA":
			case "ESO":
			case "ENA":
			case "ELI":
				return ["SOURCE", "COGNITIVE", "DIFFICULTY", "KNOWLEDGE", "QUES_TYPE"];

			// 國小健體
			case "EPE":
				return ["SOURCE", "COGNITIVE", "DIFFICULTY", "KNOWLEDGE", "QUES_TYPE"];

			// 高中數學、高中物理、高中化學、高中生物、高中地球科學、高中歷史、高中地理、高中公民與社會
			case "HMA":
			case "HPH":
			case "HCE":
			case "HBI":
			case "HEA":
			case "HHI":
			case "HGE":
			case "HCS":
				return ["SOURCE", "COGNITIVE", "DIFFICULTY", "KNOWLEDGE", "QUES_TYPE"];

			// 高中國文
			case "HPC":
				return ["SOURCE", "COGNITIVE", "DIFFICULTY", "KNOWLEDGE", "QUES_TYPE"];

			default:
				return [];
		}
	}, [eduSubject]);

	useEffect(() => {
		if (!checkMetaData) return;
		const initialCheck = checkMetaData.filter((item) => item.isRequired === true).map((item) => item.code);
		initialCheckData.current = initialCheck;
		setCheckListData(checkMetaData);
	}, [checkMetaData]);

	useEffect(() => {
		if (!outputMetaData) return;
		const initialOutput = outputMetaData;
		initialOutputData.current = initialOutput;
		setOutputListData(outputMetaData);
	}, [outputMetaData]);

	// 檢核項目
	const checkEditHandler = () => {
		setCheckIsEdit(true);
	};

	const checkChangeHandler = (event, index) => {
		const checkedState = event.target.checked;
		const tmpIsCheckList = [...checkListData];
		tmpIsCheckList[index].isRequired = checkedState;
		setCheckListData([...tmpIsCheckList]);
	};

	const checkCancelHandler = () => {
		const tmpCheckListData = [...checkListData];
		tmpCheckListData.forEach((item) => {
			initialCheckData.current.indexOf(item.code) > -1 ? (item.isRequired = true) : (item.isRequired = false);
		});

		setCheckListData(tmpCheckListData);
		setCheckIsEdit(false);
	};

	const checkSaveHandler = (type) => {
		const isCheckList = checkListData.filter((item) => item.isRequired).map((item) => item.code);
		onSaveHandle({
			type: type,
			codes: isCheckList,
		});
		setCheckIsEdit(false);
	};

	// 輸入項目
	const onOutputChange = (list) => {
		setOutputListData(list);
	};

	const outputEditHandler = () => {
		setOutputIsEdit(true);
	};

	const outputCancelHandler = () => {
		setOutputListData(initialOutputData.current);
		setOutputIsEdit(false);
	};

	const outputSaveHandler = (type) => {
		onSaveHandle({
			type: type,
			codes: outputListData,
		});
		setOutputIsEdit(false);
	};

	return (
		<UiCheckSetting>
			<TitleContainer header={CheckItemHeader(checkEditHandler, () => checkSaveHandler("check"), checkCancelHandler)}>
				<ListGroup>
					<div className="header">
						<div className="listHead">項目名稱</div>
						<div className="actionHead">必填</div>
					</div>
					<div className="content">
						{checkListData &&
							checkListData.map((item, index) => {
								return (
									<div className="list" key={item.code}>
										<div className="listTitle">{item.name}</div>
										<div className="listAction">
											{checkIsEdit ? (
												<Checkbox
													onChange={(event) => checkChangeHandler(event, index)}
													checked={item.isRequired}
													disabled={disabledArray.indexOf(item.code) !== -1 ? true : false}
												/>
											) : item.isRequired ? (
												<div className="requiredIcon"></div>
											) : null}
										</div>
									</div>
								);
							})}
					</div>
				</ListGroup>
			</TitleContainer>
			<TitleContainer
				header={OutputItemHeader(outputEditHandler, () => outputSaveHandler("output"), outputCancelHandler)}>
				{outputIsEdit ? (
					<AddList data={outputListData} onChange={onOutputChange} />
				) : (
					<ListGroup>
						<div className="header">
							<div className="listHead">項目</div>
						</div>
						<div className="content">
							{outputListData.map((item, index) => (
								<div className="list" key={index}>
									<div className="listTitle">{item}</div>
								</div>
							))}
						</div>
					</ListGroup>
				)}
			</TitleContainer>
		</UiCheckSetting>
	);
};

export const CheckItemHeader = (onEditHandler, onSaveHandler, onCancelHandler) => (
	<UiTitleHeader>
		檢核項目
		<EditGroup size="small" onEdit={onEditHandler} onSave={onSaveHandler} onCancel={onCancelHandler} />
	</UiTitleHeader>
);
export const OutputItemHeader = (onEditHandler, onSaveHandler, onCancelHandler) => (
	<UiTitleHeader>
		輸入項目
		<EditGroup size="small" onEdit={onEditHandler} onSave={onSaveHandler} onCancel={onCancelHandler} />
	</UiTitleHeader>
);

CheckSetting.propTypes = {
	checkMetaData: PropTypes.array,
	outputMetaData: PropTypes.array,
	onSaveHandle: PropTypes.func,
	currentTab: PropTypes.number,
};
