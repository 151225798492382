import React from "react";
import PropTypes from "prop-types";
import RenderRoutes from "routes/RenderRoutes";
import { useHistory } from "react-router-dom";
import { StretchMenuLayout, Dropdown, Icon, AuthProvider, AuthMachine, Loading } from "common-components";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import { BoxEditSolid } from "@onedesign/icon";
import { UiMainLayoutHeader, UiMenu, UiIconTitle, UiUserBox } from "./MainLayout.style";
import SubMenu from "antd/lib/menu/SubMenu";

const { useAuthDispatch, useAuthState } = AuthProvider;
const { eventType: AuthEventType } = AuthMachine;
/**
 * MainLayout
 */

const { Item } = Menu;

export const MainLayout = ({ routes = [] }) => {
	const authDispatch = useAuthDispatch();
	const authState = useAuthState();
	const history = useHistory();
	const { name, identity, account } = authState.context.result || {};
	const list = [
		{
			name: "登出",
			onClick: () => authDispatch(AuthEventType.LoggingOut),
			icon: "logout",
		},
	];

	return (
		<StretchMenuLayout
			env={process.env.REACT_APP_NODE_ENV}
			version={`v ${process.env.REACT_APP_VERSION || "1.0.0"}`}
			title={
				<UiIconTitle>
					<Icon name="eco" />
					題庫
				</UiIconTitle>
			}
			header={
				<UiMainLayoutHeader>
					<h1 className="title" onClick={() => history.push("/")}>
						南一數位資源入庫平台
					</h1>
					<Dropdown list={list}>
						{name && (
							<UiUserBox>
								<img src={`/assets/roles/${identity}.png`} alt={identity} />
								{account} | {name}
							</UiUserBox>
						)}
					</Dropdown>
				</UiMainLayoutHeader>
			}
			sidebar={
				<UiMenu>
					<Menu mode="inline" defaultOpenKeys={["statistics", "schedule", "revise"]}>
						<Item key="ruleSearch" icon={<Icon name="location_searching" />}>
							<Link to="/ruleSearch">規格查詢</Link>
						</Item>
						<Item key="ruleAdd" icon={<Icon name="note_add" type="outLined" />}>
							<Link to="/ruleAdd">規格新增</Link>
						</Item>
						<Item key="questionSearch" icon={<Icon name="find_in_page" type="outLined" />}>
							<Link to="/questionSearch">試題查詢</Link>
						</Item>
						<Item key="audiosSearch" icon={<Icon name="find_in_page" type="outLined" />}>
							<Link to="/audiosSearch">音檔查詢</Link>
						</Item>
						<Item key="exportList" icon={<Icon name="unarchive" type="outLined" />}>
							<Link to="/exportList">匯出列表</Link>
						</Item>
						<SubMenu key="statistics" title="檢查報告" icon={<Icon name="equalizer" type="outLined" />}>
							<Item key="questionsStatistics">
								<Link to="/questionsStatistics">題量統計</Link>
							</Item>
						</SubMenu>
						<Item key="checkList" icon={<Icon name="unarchive" type="outLined" />}>
							<Link to="/checkList">快速檢查頁</Link>
						</Item>
						{process.env.REACT_APP_NODE_ENV === "release" && (
							<Item key="questionsCheck" icon={<Icon name="unarchive" type="outLined" />}>
								<div onClick={() => window.open(process.env.REACT_APP_QUESTIONS_CHECK_URL)}>速測檢查頁</div>
							</Item>
						)}
						<SubMenu key="schedule" title="試題入庫" icon={<Icon name="unarchive" type="outLined" />}>
							<Item key="scheduleList">
								<Link to="/">一般試題入庫</Link>
							</Item>
							<Item key="equationDojo">
								<Link to="/equationDojo">無限命題入庫</Link>
							</Item>
							<Item key="livePreTest">
								<Link to="/livePreTest">Live試聽前測卷入庫</Link>
							</Item>
							<Item key="mockExam">
								<Link to="/mockExam">模擬考試題入庫</Link>
							</Item>
							<Item key="validationQuestions">
								<Link to="/validationQuestions">檢定試題入庫</Link>
							</Item>
							<Item key="vocabularytest">
								<Link to="/vocabularytest">英文單字刷題試題入庫</Link>
							</Item>
							<Item key="eduservice">
								<Link to="/eduservice">教務試題入庫</Link>
							</Item>
							<Item key="englishListening">
								<Link to="/englishListening">英聽試題入庫</Link>
							</Item>
						</SubMenu>
						<Item key="audiosImport" icon={<Icon name="unarchive" type="outLined" />}>
							<Link to="/audiosImport">音檔入庫</Link>
						</Item>
						<SubMenu
							key="revise"
							title="試題修改"
							icon={<BoxEditSolid style={{ marginRight: "5px", fontSize: "18px" }} />}>
							<Item key="questionRevise">
								<Link to="/questionRevise">一般試題修改</Link>
							</Item>
							<Item key="questionRevise?productType=2">
								<Link to="/questionRevise?productType=2">無限命題修改</Link>
							</Item>
							<Item key="questionRevise?productType=3">
								<Link to="/questionRevise?productType=3">Live試聽前測卷修改</Link>
							</Item>
							<Item key="questionRevise?productType=4">
								<Link to="/questionRevise?productType=4">模擬考試題修改</Link>
							</Item>
							<Item key="questionRevise?productType=5">
								<Link to="/questionRevise?productType=5">檢定試題修改</Link>
							</Item>
							<Item key="questionRevise?productType=6">
								<Link to="/questionRevise?productType=6">英文單字刷題試題修改</Link>
							</Item>
							<Item key="questionRevise?productType=7">
								<Link to="/questionRevise?productType=7">教務試題修改</Link>
							</Item>
							<Item key="questionRevise?productType=9">
								<Link to="/questionRevise?productType=9">英聽試題入庫</Link>
							</Item>
						</SubMenu>
						<Item key="updateAvailability" icon={<BoxEditSolid />}>
							<Link to="/updateAvailability">上下架查詢與變更</Link>
						</Item>
					</Menu>
				</UiMenu>
			}>
			{authState.value === "LoginDone" || authState.value === "UpdateUserProfile" ? (
				<RenderRoutes routes={routes} />
			) : (
				<Loading />
			)}
		</StretchMenuLayout>
	);
};

MainLayout.propTypes = {
	routes: PropTypes.array,
};
